import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import IconoPunto from "@material-ui/icons/FiberManualRecordRounded";
import IconoComentarios from "@material-ui/icons/ChatBubbleRounded";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import { loader } from "graphql.macro";
import List from "@mui/material/List";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import {
  // EncabezadoEvaluacion,
  ImagenEncabezado,
  // TituloEvaluacion,
  EnvolvedorNumeroPreguntas,
  ContenedorIconoPunto,
  CuerpoEvaluacion,
  // ContenedorExtrasEvaluacion,
  ContenedorPantallaEvaluacion,
  // RenglonExtrasEvaluacionCentrado,
  // ContenedorIconoExtrasEvaluacion,
  ContenedorTextoComentario,
  LigaComentario,
  NombredeEvaluado,
  Ordenar,
  StylesButon,
} from "./PantallaEvaluacion.styled";
import obtenerValorDeArregloDeStrings from "../../utilidades-compartidas/obtenerValorDeArregloDeStrings";
import { EnvolvedorPantalla } from "../../componentes-styled-compartidos/Pantalla.styled";
import Evaluacion from "../../evaluacion";
import Calificacion from "../../evaluacion/evaluacion-componente/Calificacion";
import EvaluacionModal from "../../evaluacion-modal";
import Comentarios from "./PantallaEvaluacionComentarios";
import Boton from "../../boton";
import ControladorDeslizador from "./PantallaEvaluacionDeslizador";
import IndicadorCarga from "../../indicador-carga";
import Configuraciones from "../../../servicios/encuestas";
import { Alerta } from "../../alerta";
import Constantes from "../../../recursos/constantes";
import avatarTaller from "../../../recursos/imagenes/ico-taller.png";
import ModalEvaluacion from "./ModalEvaluacion";
// import { TituloMisPolizas } from "../../pantalla-mis-polizas/pantalla-mis-polizas-componente/PantallaMisPolizas.styled";

const valores = {
  comentarios: "",
  respuestaComentario: {},
  respuestaEstrellas: [],
  respuestaCalificacion: [],
};

const nombreCookie = Constantes.nombreDeCookie;

const VALIDAR_ENCUESTA = loader(
  "../../../graphQL/query/encuesta/validar_encuesta.graphql"
);

const OBTENER_ENCUESTA = loader(
  "../../../graphQL/query/encuesta/obtener_encuesta.graphql"
);
const ENVIAR_ENCUESTA = loader(
  "../../../graphQL/mutation/encuesta/enviar_encuesta.graphql"
);
const EVENTOS_PASADOS = loader(
  "../../../graphQL/query/reporte/listado_actualizaciones_reporte.graphql"
);

const SKIP_SURVEY = loader(
  "../../../graphQL/mutation/encuesta/encuesta_guardarEstatusEncuesta.graphql"
);
// andru
const PantallaEvaluacionTaller = () => {
  const history = useHistory();
  const location = useLocation();
  const estadoApp = useSelector((estado) => estado);
  // const dispatch = useDispatch();
  const [cookie] = useCookies([nombreCookie]);
  const objetoCookie = cookie[nombreCookie];
  let nombreCompleto = "";
  let numeroReporte;
  // const numeroSiniestro = estadoApp.numeroSiniestroReporte || "";

  if (
    location &&
    location.search &&
    location.search.includes("numeroReporte")
  ) {
    const params = new URLSearchParams(location.search);
    numeroReporte = params.get("numeroReporte");
  } else if (estadoApp.datosReporte && estadoApp.datosReporte.numeroReporte) {
    numeroReporte = estadoApp.datosReporte.numeroReporte;
  } else {
    history.push({
      pathname: "/",
      state: {
        mostrarAlerta: true,
      },
    });
  }
  const imagenEncabezado = avatarTaller;
  const nombreTallerDefault = "tu taller";
  let nombreTallerEstado;
  if (estadoApp.datosTaller && estadoApp.datosTaller.data) {
    nombreTallerEstado = obtenerValorDeArregloDeStrings(
      estadoApp.datosTaller.data,
      "NombreTaller: "
    );
  }
  if (estadoApp.nombreConductor) {
    nombreCompleto = estadoApp.nombreConductor;
  } else if (
    estadoApp.datosPoliza &&
    estadoApp.datosPoliza.nombreCompletoAsegurado
  ) {
    nombreCompleto = estadoApp.datosPoliza.nombreCompletoAsegurado;
  } else if (objetoCookie && objetoCookie.nombreReporta) {
    nombreCompleto = objetoCookie.nombreReporta;
  } else if (objetoCookie && objetoCookie.NombreAsegurado) {
    nombreCompleto = objetoCookie.NombreAsegurado;
  } else {
    nombreCompleto = "Asegurado";
  }

  const [pantalla, asignarValorPantalla] = useState("");
  const [preguntasEstrellas, asignarValorPreguntasEstrellas] = useState([]);
  const [preguntaCalificacion, asignarValorPreguntaCalificacion] = useState([]);
  const [evaluaciones, asignarValorEvaluaciones] = useState([]);
  const [preguntaActual, asignarValorPreguntaActual] = useState(0);
  const [cargando, asignarValorCargando] = useState(false);
  const [mostrarModal, asignarValorMostrarModal] = useState(false);
  const [mostrarError, asignarValorMostrarError] = useState(false);
  const [valorModalEncuesta, asignarValorModalEncuesta] = useState(false);
  const [mostrar, setmostrarmodal] = useState(false);
  const [nombreTaller, asignarValorNombreTaller] = useState(
    nombreTallerEstado || nombreTallerDefault
  );
  const [sliderValue, setSliderValue] = useState(10);
  const [selectedButton, setSelectedButton] = useState(null); // Estado para almacenar el botón seleccionado
  const [pregunta1Calificada, SetPreg1Calificada] = useState(null);
  const [pregunta2Calificada, SetPreg2Calificada] = useState(null);
  const [pregunta3Calificada, SetPreg3Calificada] = useState(null);
  const [pregunta4Calificada, SetPreg4Calificada] = useState(null);
  const [pregunta5Calificada, SetPreg5Calificada] = useState(null);
  // const [currentSlide, setCurrentSlide] = useState(0); Estado para rastrear el slide actual
  const [buttons, setButtons] = useState([]);
  // const [otorgarReconocimiento, setReconocimiento] = useState(false);

  const cambiarPantalla = (pantallaACambiar) => {
    asignarValorPantalla(pantallaACambiar);
  };

  const [
    validacionEncuesta,
    { data: validarEncuesta, loading: cargandoValidarEncuesta },
  ] = useLazyQuery(VALIDAR_ENCUESTA, { fetchPolicy: "no-cache" });

  const [
    obtenerEventosPasados,
    { data: eventosPasados, loading: esperaEventosPasados },
  ] = useLazyQuery(EVENTOS_PASADOS);

  const { data: encuestaData, loading: esperaEncuesta } = useQuery(
    OBTENER_ENCUESTA,
    {
      variables: { idEncuesta: Configuraciones.evaluacionTallerID },
    }
  );
  const [
    enviarEncuesta,
    {
      data: respuestaEncuesta,
      error: errorEnvioEncuesta,
      loading: esperaEnvio,
    },
  ] = useMutation(ENVIAR_ENCUESTA, { errorPolicy: "all" });

  const [
    skipSurvey,
    {
      data: dataSkipSurvey,
      loading: loadingSkipSurvey,
      error: errorSkipSurvey,
    },
  ] = useMutation(SKIP_SURVEY, { errorPolicy: "all" });

  const diccionario = {
    encabezadoTaller1: "El servicio de ",
    encabezadoTaller2: " ha finalizado.",
    principal: "Califica tu experiencia",
    titulo: "Indica tu nivel de satisfacción con:",
    cuerpo: "Todas las calificaciones son anónimas",
    textoComentario: "Agregar comentario",
    botonEnviar: "Calificar",
    texto1: "Califica la atención de tu centro de reparación",
    modal: {
      titulo: "Ocurrió un problema",
      cuerpo: "La acción no pudo ser completada",
      boton: "Ok",
    },
  };
  const Bombo1p = [
    // servicio y atencion
    { id: 1, estado: 1, label: "Buena atención" },
    { id: 2, estado: 1, label: "Buena comunicación" },
    { id: 3, estado: 1, label: "Amabilidad del personal" },
    { id: 4, estado: 1, label: "Cuidado al detalle" },
  ];
  const Bombo1n = [
    // servicio y atencion
    { id: 1, estado: 0, label: "Mala atención" },
    { id: 2, estado: 0, label: "Falta de comunicación" },
    { id: 3, estado: 0, label: "Falta de amabilidad" },
    { id: 4, estado: 0, label: "Falta de cuidado al detalle" },
  ];
  const Bombo2p = [
    // seguimiento a la reparacion
    { id: 1, estado: 1, label: "Información oportuna del estatus" },
    { id: 2, estado: 1, label: "Información de cambios en fecha de entrega" },
    { id: 3, estado: 1, label: "Confirmación de llegada de refacciones" },
    { id: 4, estado: 1, label: "Confirmación de fecha de entrega" },
  ];
  const Bombo2n = [
    // seguimiento a la reparacion
    { id: 1, estado: 0, label: "Falta de información de estatus" },
    {
      id: 2,
      estado: 0,
      label: "Falta de información de cambios de fecha de entrega",
    },
    {
      id: 3,
      estado: 0,
      label: "Falta de confirmación de llegada de refacciones",
    },
    { id: 4, estado: 0, label: "Falta de confirmación de fecha de entrega" },
  ];
  const Bombo3p = [
    // Entrega en tiempo del veiculo
    { id: 1, estado: 1, label: "Entrega en tiempo" },
    { id: 2, estado: 1, label: "Entrega con la calidad esperada" },
    { id: 3, estado: 1, label: "Entrega limpia y en orden" },
    { id: 4, estado: 1, label: "Se cumplió con lo acordado" },
  ];
  const Bombo3n = [
    // Entrega en tiempo del veiculo
    { id: 1, estado: 0, label: "Entrega tardía" },
    { id: 2, estado: 0, label: "Entrega con defectos de calidad" },
    { id: 3, estado: 0, label: "Entrega manchado o sucio" },
    { id: 4, estado: 0, label: "No se cumplió con lo acordado" },
  ];
  const Bombo4p = [
    // Calidad de la reparacion
    { id: 1, estado: 1, label: "Refacciones de calidad" },
    { id: 2, estado: 1, label: "Mano de obra de calidad" },
    { id: 3, estado: 1, label: "No presenta ruidos extraños" },
    { id: 4, estado: 1, label: "Calidad en la hojalatería y pintura" },
    { id: 5, estado: 1, label: "Unidad en perfectas condiciones" },
  ];
  const Bombo4n = [
    // Calidad de la reparacion
    { id: 1, estado: 0, label: "Mala calidad de refacciones" },
    { id: 2, estado: 0, label: "Mano de obra deficiente" },
    { id: 3, estado: 0, label: "Ruidos extraños en la unidad" },
    { id: 4, estado: 0, label: "Mala calidad en hojalatería y pintura" },
    { id: 5, estado: 0, label: "Recibió daños mi unidad" },
  ];
  const Bombo5p = [
    // ¿Qué tan satisfecho te encuentras con tu centro de reparación?
    { id: 1, estado: 1, label: "Fue muy rápido" },
    { id: 2, estado: 1, label: "Sigue los protocolos" },
    { id: 3, estado: 1, label: "Buena atención y servicio" },
    { id: 4, estado: 1, label: "Calidad en la reparación" },
    { id: 5, estado: 1, label: "Seguimiento a la reparación" },
  ];
  const Bombo5n = [
    // ¿Qué tan satisfecho te encuentras con tu centro de reparación?
    { id: 1, estado: 0, label: "Fue muy lento" },
    { id: 2, estado: 0, label: "No sigue protocolos" },
    { id: 3, estado: 0, label: "Mala atención y servicio" },
    { id: 4, estado: 0, label: "Mala calidad en la reparación" },
    { id: 5, estado: 0, label: "Seguimiento a la reparación" },
  ];

  useEffect(() => {
    if (
      cargandoValidarEncuesta ||
      esperaEventosPasados ||
      esperaEncuesta ||
      esperaEnvio
    ) {
      asignarValorCargando(true);
    } else if (
      !cargandoValidarEncuesta &&
      !esperaEventosPasados &&
      !esperaEncuesta &&
      !esperaEnvio
    ) {
      asignarValorCargando(false);
      asignarValorModalEncuesta(false); // true
    }
  }, [
    cargandoValidarEncuesta,
    esperaEventosPasados,
    esperaEncuesta,
    esperaEnvio,
  ]);

  useEffect(() => {
    if (location.state?.omitida) {
      return;
    }

    if (!location || !location.state || !location.state.evaluadoPreviamente) {
      validacionEncuesta({
        variables: {
          numeroReporte,
          idEncuesta: Configuraciones.evaluacionTallerID,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      !cargandoValidarEncuesta &&
      validarEncuesta &&
      validarEncuesta.validar_encuesta &&
      validarEncuesta.validar_encuesta.codigo === "IMENC100007"
    ) {
      history.push({
        pathname: "/pasos-progreso",
        search: `?numeroReporte=${numeroReporte}`,
      });
    }

    if (
      !cargandoValidarEncuesta &&
      validarEncuesta &&
      validarEncuesta.validar_encuesta &&
      validarEncuesta.validar_encuesta.codigo === "IDENC100010"
    ) {
      history.push({
        pathname: "/pasos-progreso",
        search: `?numeroReporte=${numeroReporte}`,
      });
    }
  }, [validarEncuesta, cargandoValidarEncuesta]);

  useEffect(() => {
    if (
      !esperaEnvio &&
      errorEnvioEncuesta &&
      errorEnvioEncuesta.graphQLErrors &&
      errorEnvioEncuesta.graphQLErrors[0] &&
      (errorEnvioEncuesta.graphQLErrors[0].errorType === "ExecutionTimeout" ||
        errorEnvioEncuesta.graphQLErrors[0].message === "Execution timed out.")
    ) {
      validacionEncuesta({
        variables: {
          numeroReporte,
          idEncuesta: Configuraciones.evaluacionAjustadorID,
        },
      });
    } else if (
      !esperaEnvio &&
      respuestaEncuesta &&
      respuestaEncuesta.enviar_encuesta &&
      (respuestaEncuesta.enviar_encuesta.codigo === "IMENC100001" ||
        respuestaEncuesta.enviar_encuesta.mensaje ===
          "La encuesta se encuentra omitida.")
    ) {
      // primero mostrar que se mando
      setmostrarmodal(true);
      setTimeout(() => {
        history.push({
          pathname: "/pasos-progreso",
          search: `?numeroReporte=${numeroReporte}`,
        });
      }, 2000);
    } else if (
      !esperaEnvio &&
      respuestaEncuesta &&
      respuestaEncuesta.enviar_encuesta &&
      !respuestaEncuesta.enviar_encuesta.completado
    ) {
      asignarValorCargando(false);
      asignarValorMostrarModal(false);
      asignarValorModalEncuesta(false);
      asignarValorMostrarError(true);
    }
  }, [respuestaEncuesta, esperaEnvio]);

  useEffect(() => {
    if (nombreTaller === nombreTallerDefault) {
      obtenerEventosPasados({
        variables: { numeroReporte },
      });
    }
  }, []);

  useEffect(() => {
    if (eventosPasados?.listado_actualizaciones_reporte?.dato?.ajuste) {
      eventosPasados.listado_actualizaciones_reporte.dato.ajuste.forEach(
        (evento) => {
          switch (evento.tipoMensaje) {
            case 10:
              {
                const { descripciones } = evento;
                asignarValorNombreTaller(
                  obtenerValorDeArregloDeStrings(
                    descripciones,
                    "NombreTaller: "
                  )
                );
              }
              break;
            default:
          }
        }
      );
    }
  }, [eventosPasados]);

  useEffect(() => {
    if (
      !esperaEncuesta &&
      encuestaData &&
      encuestaData.obtener_encuesta &&
      encuestaData.obtener_encuesta.dato &&
      encuestaData.obtener_encuesta.dato.paginas
    ) {
      const { paginas } = encuestaData.obtener_encuesta.dato;
      const acumuladoPreguntasEstrellas = [];
      const acumuladoEvaluacionEstrellas = [];
      const acumuladoPreguntasCalificacion = [];
      const acumuladoEvaluacionCalificacion = [];
      paginas.forEach((pagina) => {
        pagina.preguntas.forEach((pregunta) => {
          switch (pregunta.tipoDescripcion) {
            case "Estrellas":
              acumuladoPreguntasEstrellas.push(
                pregunta.descripcion.replace("@@Taller", nombreTaller)
              );
              acumuladoEvaluacionEstrellas.push({
                idEP: pregunta.idEP,
                id: pregunta.id,
                preguntaId: pregunta.respuestas[0].preguntaId,
                minicomentario: "",
              });
              break;
            case "Calificacion":
              //  seagrega
              acumuladoPreguntasEstrellas.push(
                pregunta.descripcion.replace("@@Taller", nombreTaller)
              );
              acumuladoEvaluacionEstrellas.push({
                idEP: pregunta.idEP,
                id: pregunta.id,
                preguntaId: pregunta.respuestas[0].preguntaId,
                minicomentario: "",
              });
              // --------
              // acumuladoPreguntasCalificacion.push(pregunta.descripcion);
              // acumuladoEvaluacionCalificacion.push({
              //   idEP: pregunta.idEP,
              //   id: pregunta.id,
              //   preguntaId: pregunta.respuestas[0].preguntaId,
              // });
              break;
            case "Abierta":
              valores.respuestaComentario = {
                idEP: pregunta.idEP,
                id: pregunta.id,
                preguntaId: pregunta.respuestas[0].preguntaId,
              };
              break;
            default:
          }
        });
      });
      asignarValorPreguntasEstrellas(acumuladoPreguntasEstrellas);
      asignarValorPreguntaCalificacion(acumuladoPreguntasCalificacion);
      valores.respuestaEstrellas = acumuladoEvaluacionEstrellas;
      valores.respuestaCalificacion = acumuladoEvaluacionCalificacion;
    }
  }, [encuestaData, esperaEncuesta]);

  const dormir = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const validacionPreguntaMarcada = (pregunta) => {
    switch (pregunta) {
      case 0:
        if (pregunta1Calificada !== null) {
          if (pregunta1Calificada.bombo === 1) {
            setButtons(Bombo1p);
          } else {
            setButtons(Bombo1n);
          }
          setSelectedButton(pregunta1Calificada.index);
        }
        break;
      case 1:
        if (pregunta2Calificada !== null) {
          if (pregunta2Calificada.bombo === 1) {
            setButtons(Bombo2p);
          } else {
            setButtons(Bombo2n);
          }
          setSelectedButton(pregunta2Calificada.index);
        }
        break;
      case 2:
        if (pregunta3Calificada !== null) {
          if (pregunta3Calificada.bombo === 1) {
            setButtons(Bombo3p);
          } else {
            setButtons(Bombo3n);
          }
          setSelectedButton(pregunta3Calificada.index);
        }
        break;
      case 3:
        if (pregunta4Calificada !== null) {
          if (pregunta4Calificada.bombo === 1) {
            setButtons(Bombo4p);
          } else {
            setButtons(Bombo4n);
          }
          setSelectedButton(pregunta4Calificada.index);
        }
        break;
      case 4:
        if (pregunta5Calificada !== null) {
          if (pregunta5Calificada.bombo === 1) {
            setButtons(Bombo4p);
          } else {
            setButtons(Bombo4n);
          }
          setSelectedButton(pregunta5Calificada.index);
        }
        break;
      default:
    }
  };
  const seleccionaboton = (pregunta, indiceboton, estado) => {
    switch (pregunta) {
      case 0:
        SetPreg1Calificada({ index: indiceboton, bombo: estado });
        break;
      case 1:
        SetPreg2Calificada({ index: indiceboton, bombo: estado });
        break;
      case 2:
        SetPreg3Calificada({ index: indiceboton, bombo: estado });
        break;
      case 3:
        SetPreg4Calificada({ index: indiceboton, bombo: estado });
        break;
      case 4:
        SetPreg5Calificada({ index: indiceboton, bombo: estado });
        break;
      default:
    }
  };
  const actualizaMinicomentarios = (actual, valor) => {
    switch (preguntaActual) {
      case 0:
        if (valor >= 3) {
          setButtons(Bombo1p);
        } else {
          setButtons(Bombo1n);
        }
        break;
      case 1:
        if (valor >= 3) {
          setButtons(Bombo2p);
        } else {
          setButtons(Bombo2n);
        }
        break;
      case 2:
        if (valor >= 3) {
          setButtons(Bombo3p);
        } else {
          setButtons(Bombo3n);
        }
        break;
      case 3:
        if (valor >= 3) {
          setButtons(Bombo4p);
        } else {
          setButtons(Bombo4n);
        }
        break;
      case 4:
        if (valor >= 3) {
          setButtons(Bombo5p);
        } else {
          setButtons(Bombo5n);
        }
        break;
      default:
    }
    setSelectedButton(null);
  };

  useEffect(() => {
    if (preguntaActual === 5) {
      setButtons([]);
    }
    actualizaMinicomentarios(preguntaActual);
    validacionPreguntaMarcada(preguntaActual);
  }, [preguntaActual]);

  const alCambiarEvaluacion = async (valor) => {
    // Actualizar valor de las evaluaciones
    const evaluacionesOriginales = [...evaluaciones];
    evaluacionesOriginales[preguntaActual] = valor;
    asignarValorEvaluaciones(evaluacionesOriginales);

    // Agregar el comentario a la respuesta que se mandará al backend de HDI
    const respuestaEvaluacionOriginal = [...valores.respuestaEstrellas];
    respuestaEvaluacionOriginal[preguntaActual] = {
      ...respuestaEvaluacionOriginal[preguntaActual],
      evaluacion: valor,
    };
    // Actualizar minicomentarios
    actualizaMinicomentarios(preguntaActual, valor);
    valores.respuestaEstrellas = respuestaEvaluacionOriginal;
    // si el valor es positivo mostrar comentarios positivos

    // await dormir(500);
    // if (preguntaActual < preguntasEstrellas.length - 1) {
    //   asignarValorPreguntaActual(preguntaActual + 1);
    // } else
    // if (preguntaActual === preguntasEstrellas.length - 1) {
    //   // asignarValorPreguntaActual(0);
    //   cambiarPantalla("comentarios");
    // }
    setSelectedButton(null);
  };

  const alCambiarComentarios = (texto) => {
    if (texto) {
      valores.comentarios = texto;

      // Agregar el comentario a la respuesta que se mandará al backend de HDI
      valores.respuestaComentario = {
        ...valores.respuestaComentario,
        comentario: texto,
      };
      asignarValorPantalla("");
    }
  };

  const enviarEvaluacion = () => {
    // Si no se agregó un comentario, enviar objeto vacío
    if (valores.comentarios === "") {
      valores.respuestaComentario = {};
      // cambiarPantalla("comentarios");
      // return;
    }

    enviarEncuesta({
      variables: {
        evaluaciones: JSON.stringify(valores.respuestaEstrellas),
        calificaciones: JSON.stringify(valores.respuestaCalificacion),
        comentario: JSON.stringify(valores.respuestaComentario),
        reconocimiento: JSON.stringify({}),
        encuestaId: Configuraciones.evaluacionTallerID,
        evalua: nombreCompleto,
        // evaluado: nombreTaller === nombreTallerDefault ? "" : nombreTaller,
        evaluado: nombreTaller,
        numeroReporte,
      },
    });
  };

  const prepararEvaluacion = () => {
    // if (valores.comentarios === "") {
    //   // asignarValorMostrarModal(true);
    //   cambiarPantalla("comentarios");
    // } else {
    //   enviarEvaluacion();
    // }
    enviarEvaluacion();
  };

  const alCambiarCalificacion = (calificacion) => {
    if (calificacion) {
      // Actualizar respuesta de la calificacion
      const respuestaCalificacionActualizada = [
        ...valores.respuestaCalificacion,
      ];
      respuestaCalificacionActualizada[0] = {
        ...respuestaCalificacionActualizada[0],
        calificacion,
      };

      valores.respuestaCalificacion = respuestaCalificacionActualizada;
    }

    enviarEvaluacion();
  };

  const handleSliderChange = async (newValue) => {
    // Actualizar valor de las evaluaciones
    const evaluacionesOriginales = [...evaluaciones];
    evaluacionesOriginales[preguntaActual] = newValue;
    asignarValorEvaluaciones(evaluacionesOriginales);

    // Agregar el comentario a la respuesta que se mandará al backend de HDI
    const respuestaEvaluacionOriginal = [...valores.respuestaEstrellas];
    respuestaEvaluacionOriginal[preguntaActual] = {
      ...respuestaEvaluacionOriginal[preguntaActual],
      evaluacion: newValue,
    };

    valores.respuestaEstrellas = respuestaEvaluacionOriginal;
    // --------------
    setSliderValue(newValue);
    await dormir(500);
  };

  // const handleSlideChange = (newIndex) => {
  //   setCurrentSlide(newIndex); // Cambia el estado del slide actual
  //   setSelectedButton(null); // Reinicia la selección de botones
  // };

  const handleClick = (index, label, estado) => {
    seleccionaboton(preguntaActual, index, estado);
    setSelectedButton(index); // Actualiza el estado con el índice del botón clicado
    // recibir el texto e insertarselo a la respuesta.
    // const evaluacionesOriginales = [...evaluaciones];
    // evaluacionesOriginales[preguntaActual] = index;

    const respuestaEvaluacionOriginal = [...valores.respuestaEstrellas];
    respuestaEvaluacionOriginal[preguntaActual] = {
      ...respuestaEvaluacionOriginal[preguntaActual],
      minicomentario: label,
    };
    valores.respuestaEstrellas = respuestaEvaluacionOriginal;
  };

  // const buttonTexts = [
  //   [
  //     "Buen servicio y atención",
  //     "Explico el proceso a detalle",
  //     "Comprometido con la situación",
  //     "Tiempo de llegada",
  //   ], // Textos para el primer slide
  //   ["Texto 2-1", "Texto 2-2", "Texto 2-3", "Texto 2-4"], // Textos para el segundo slide
  //   ["Texto 3-1", "Texto 3-2", "Texto 3-3", "Texto 3-4"], // Textos para el tercer slide
  //   ["Texto 4-1", "Texto 4-2", "Texto 4-3", "Texto 4-4"], // Textos para el cuarto slide
  //   ["Texto 5-1", "Texto 5-2", "Texto 5-3", "Texto 5-4"], // Textos para el quinto slide
  // ];

  const habilitarBotonEnvio = () => {
    if (
      evaluaciones.length !== preguntasEstrellas.length ||
      evaluaciones.includes(undefined)
    ) {
      return true;
    }
    return false;
  };

  const reedireccionarOmitir = () => {
    // Estatus de evaluacion:
    /*
      1- Pendiente
      2- Omitido
      3- Contestado
    */
    if (location?.state?.omitida) {
      history.push({
        pathname: "/pasos-progreso",
        search: `?numeroReporte=${numeroReporte}`,
      });
      return;
    }
    skipSurvey({
      variables: {
        idEncuesta: 2,
        estatusEvaluacion: 2,
        numeroReporte: Number(numeroReporte),
        evalua: nombreCompleto,
        evaluado: nombreTallerEstado || "",
        lineaNegocio: "AUTR",
        siniestro: estadoApp.datosNumeroSiniestroGlobal || "",
      },
    });
    history.push({
      pathname: "/pasos-progreso",
      search: `?numeroReporte=${numeroReporte}`,
      state: {
        encuesta: "omitida",
      },
    });
  };

  useEffect(() => {
    if (loadingSkipSurvey) {
      asignarValorCargando(true);
    }

    if (
      !loadingSkipSurvey &&
      !dataSkipSurvey?.encuesta_guardarEstatusEncuesta.codigo === "ERR00001"
    ) {
      asignarValorCargando(false);
      asignarValorMostrarError(true);
      return;
    }

    if (
      dataSkipSurvey?.encuesta_guardarEstatusEncuesta.completado &&
      !loadingSkipSurvey &&
      dataSkipSurvey?.encuesta_guardarEstatusEncuesta.codigo !== "ERR00001"
    ) {
      asignarValorCargando(false);
      history.push({
        pathname: "pasos-progreso",
        search: `?numeroReporte=${numeroReporte}`,
      });
    }
  }, [dataSkipSurvey, loadingSkipSurvey, errorSkipSurvey]);

  let pantallaAMostrar = (
    <EnvolvedorPantalla key={v4()}>
      {cargando ? <IndicadorCarga /> : null}
      <EvaluacionModal
        mostrar={mostrarModal}
        pregunta={preguntaCalificacion[0]}
        asignarCalificacion={alCambiarCalificacion}
      />
      <Alerta
        colorAlerta="rojo"
        textoEncabezado={diccionario.modal.titulo}
        textoCuerpo={diccionario.modal.cuerpo}
        etiquetaBoton={diccionario.modal.boton}
        mostrarModal={mostrarError}
        funcionLlamadaBoton={() => {
          asignarValorMostrarError(false);
        }}
        mostrarCierre={false}
      />

      <Alerta
        mostrarModal={valorModalEncuesta}
        colorAlerta="azul"
        tipoIcono="palomita"
        textoEncabezado={`${estadoApp.NombreUsuarioPerfil.data}, queremos escucharte!`}
        textoCuerpo="Inspíranos a mejorar la atención del centro de reparación contestando la siguiente encuesta: "
        etiquetaBoton="Contestar"
        etiquetaBoton2="Omitir"
        funcionLlamadaBoton={() => asignarValorModalEncuesta(false)}
        funcionLlamadaBoton2={reedireccionarOmitir}
        temaBoton2="simple"
        manejarCierre={() => asignarValorModalEncuesta(false)}
      />
      {mostrar ? <ModalEvaluacion /> : null}
      <ContenedorPantallaEvaluacion>
        <List>
          <Ordenar>
            <ImagenEncabezado src={imagenEncabezado} />
          </Ordenar>
          <CuerpoEvaluacion>{diccionario.texto1}</CuerpoEvaluacion>
          <NombredeEvaluado>{nombreTaller}</NombredeEvaluado>
        </List>
        {/* <EncabezadoEvaluacion>
          <ImagenEncabezado src={imagenEncabezado} />
          {diccionario.encabezadoTaller1}
          {nombreTaller}
          {diccionario.encabezadoTaller2}
        </EncabezadoEvaluacion> */}
        {/* <TituloMisPolizas>{diccionario.principal}</TituloMisPolizas>
        <br />
        <TituloEvaluacion>{diccionario.titulo}</TituloEvaluacion> */}
        <CarouselProvider
          naturalSlideWidth={50}
          naturalSlideHeight={20}
          totalSlides={preguntasEstrellas.length}
          currentSlide={preguntaActual}
          dragEnabled={false}
          isPlaying={false}
          lockOnWindowScroll
        >
          <Slider moveThreshold={0.6} style={{ height: "200px" }}>
            {preguntasEstrellas.map((valor, indice) =>
              indice === 5 ? (
                <Calificacion
                  value={sliderValue}
                  texto={valor}
                  onSliderChange={handleSliderChange}
                />
              ) : (
                <Slide index={indice}>
                  <Evaluacion
                    titulo={valor}
                    evaluacion={evaluaciones[indice]}
                    asignarEvaluacion={alCambiarEvaluacion}
                    // onVisible={() => handleSlideChange(0)}
                  />
                </Slide>
              )
            )}
          </Slider>
          {/* Botón anterior con ícono de flecha izquierda */}
          <ButtonBack
            style={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <ArrowBackIosIcon />
          </ButtonBack>

          {/* Botón siguiente con ícono de flecha derecha */}
          <ButtonNext
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <ArrowForwardIosIcon />
          </ButtonNext>
          <ControladorDeslizador preguntaActual={asignarValorPreguntaActual} />
        </CarouselProvider>
        <div style={StylesButon.container}>
          {buttons.map((button, index) => (
            <Button
              key={button.id}
              variant="outlined" // Siempre mantiene el estilo outlined
              style={{
                ...StylesButon.button,
                borderColor:
                  selectedButton === index ? "green" : "rgba(0, 0, 0, 0.23)", // Cambia el color del borde
                color: selectedButton === index ? "green" : "black", // Cambia el color del texto cuando está seleccionado
                textTransform: "none",
              }}
              onClick={() => handleClick(index, button.label, button.estado)} // Llama a handleClick con el índice del botón
            >
              {button.label}
            </Button>
          ))}
          {/* <Button variant="outlined" style={StylesButon.button}>
            Botón 1
          </Button> */}
        </div>
        <EnvolvedorNumeroPreguntas>
          {preguntasEstrellas.map((valor, indice) => (
            <ContenedorIconoPunto
              key={valor}
              marcado={indice === preguntaActual}
            >
              <IconoPunto style={{ fontSize: 12 }} />
            </ContenedorIconoPunto>
          ))}
        </EnvolvedorNumeroPreguntas>
        {/* <CuerpoEvaluacion>{diccionario.cuerpo}</CuerpoEvaluacion> */}
        {/* <ContenedorExtrasEvaluacion>
          <RenglonExtrasEvaluacionCentrado
            onClick={() => {
              cambiarPantalla("comentarios");
            }}
          >
            <ContenedorIconoExtrasEvaluacion>
              <IconoComentarios style={{ fontSize: 18 }} />
            </ContenedorIconoExtrasEvaluacion>
            <ContenedorTextoComentario>
              {diccionario.textoComentario}
            </ContenedorTextoComentario>
          </RenglonExtrasEvaluacionCentrado>
        </ContenedorExtrasEvaluacion> */}
        <Ordenar>
          <LigaComentario
            onClick={() => {
              cambiarPantalla("comentarios");
            }}
          >
            <IconoComentarios style={{ fontSize: 23, position: "relative" }} />
            <ContenedorTextoComentario>
              Agregar comentario
            </ContenedorTextoComentario>
          </LigaComentario>
        </Ordenar>
        <Boton
          etiqueta={diccionario.botonEnviar}
          id="botonEvaluacion"
          enClick={prepararEvaluacion}
          deshabilitado={habilitarBotonEnvio()}
        />
        <Ordenar>
          {/* <Boton
            style={{ marginTop: "1rem" }}
            etiqueta="Ahora no"
            tema="simple"
            enClick={reedireccionarOmitir}
          /> */}
          <LigaComentario
            onClick={() => {
              reedireccionarOmitir();
            }}
          >
            <ContenedorTextoComentario>Ahora no</ContenedorTextoComentario>
          </LigaComentario>
        </Ordenar>
      </ContenedorPantallaEvaluacion>
    </EnvolvedorPantalla>
  );

  if (pantalla === "comentarios") {
    pantallaAMostrar = (
      <Comentarios
        alAceptar={alCambiarComentarios}
        alRegresar={() => {
          cambiarPantalla("");
        }}
      />
    );
  }

  return pantallaAMostrar;
};

export default PantallaEvaluacionTaller;
